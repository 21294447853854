<template>
  <div class="page">
    <validation-observer
      ref="registerForm"
      #default="{invalid}"
    >
      <div class="row">
        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
          <b-card title="User Information">
            <b-form-group
              label="Name"
              label-for="name"
            >
              <validation-provider
                #default="{ errors }"
                name="Name"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="name"
                    v-model="user.name"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="name"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Role"
              label-for="role"
            >
              <validation-provider
                #default="{ errors }"
                name="Role"
                rules="required"
              >
                <v-select
                  id="role"
                  v-model="user.role"
                  :options="roleOptions"
                  name="role"
                  :clearable="false"
                  :searchable="false"
                  label="text"
                  :reduce="role => role.value"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              v-if="user.role === 'agent'"
              label="MLS Agent ID"
              label-for="mls-id"
            >
              <validation-provider
                #default="{ errors }"
                name="MLS ID"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="mls-id"
                    v-model="user.mlsId"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="mls-id"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              v-if="user.role === 'agent'"
              label="Office Name"
              label-for="office-name"
            >
              <validation-provider
                #default="{ errors }"
                name="Office Name"
                rules="required"
              >
                <v-select
                  v-model="user.office"
                  label="officeName"
                  :filterable="false"
                  :options="officeOptions"
                  @search="onSearch"
                >
                  <template slot="no-options">
                    type to search for offices
                  </template>
                  <template
                    slot="option"
                    slot-scope="officeOptions"
                  >
                    <div class="d-center">
                      <p>{{ officeOptions.officeName }}</p>
                      <p>{{ officeOptions.officeShortName }} | {{ officeOptions.officePhone }}</p>
                    </div>
                  </template>
                  <template
                    slot="selected-option"
                    slot-scope="officeOptions"
                  >
                    <div class="selected d-center">
                      <p>{{ officeOptions.officeName }}</p>
                      <p>{{ officeOptions.officeShortName }} | {{ officeOptions.officePhone }}</p>
                    </div>
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              label="Email"
              label-for="email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="email"
                    v-model="user.email"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="email"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Phone"
              label-for="phone"
            >
              <validation-provider
                #default="{ errors }"
                name="Phone"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="phone"
                    v-model="user.phone"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="phone"
                    @input="formatPhoneNumber(user.phone)"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox
                v-model="user.isAdmin"
                value="1"
                class="custom-control-primary"
              >
                Is Admin
              </b-form-checkbox>
            </b-form-group>
            <b-button
              variant="outline-primary"
              class="mb-2"
              :disabled="invalid"
              @click="save"
            >
              Save
            </b-button>
          </b-card>
        </div>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BCard, BFormGroup, BButton, BInputGroup, BFormInput, BFormCheckbox,
} from 'bootstrap-vue'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard, BButton, ValidationProvider, ValidationObserver, BFormGroup, BInputGroup, BFormInput, vSelect, BFormCheckbox,
  },
  data() {
    return {
      required,
      roleOptions: [
        { value: 'assistant', text: 'Assistant' },
      ],
      officeOptions: [],
      user: {
        name: '',
        role: 'assistant',
        mlsId: '',
        email: '',
        phone: '',
        isAdmin: 0,
        office: null,
      },
    }
  },
  computed: {
    getSignedInClient() {
      return this.$store.state.clientInfo
    },
  },
  created() {
    if (this.getSignedInClient) {
      if (this.getSignedInClient.office) {
        this.user.office = this.getSignedInClient.office
      }
    }
  },
  methods: {
    save() {
      const data = {
        email: this.user.email,
        name: this.user.name,
        role: this.user.role,
        is_admin: this.user.isAdmin,
        phone: this.user.phone,
        agent_mls_loginName: this.user.mlsId,
        resource_office_id: this.user.office.officeID,
      }

      this.$store.dispatch('teams/addTeamMember', data).then(response => {
        if (response.code === 200) {
          this.showToast('Agent Added', 'CheckCircleIcon', 'User has been Added successfully', 'success', 'bottom-right')

          this.$router.push({
            name: 'teams',
          })
        }
      }).catch(err => {
        console.error(err)
      })
    },
    onSearch(search, loading) {
      if (search.length) {
        loading(true)
        this.getOfficeOptions(search, loading)
      }
    },
    getOfficeOptions(search, loading) {
      this.$store.dispatch('offices/getOfficeNames', { key: search }).then(response => {
        if (response.code === 200) {
          loading(false)
          this.officeOptions = response.data
        }
      }).catch(err => {
        console.error(err)
      })
    },
    formatPhoneNumber(data) {
      const cleaned = ('' + data).replace(/\D/g, '')
      const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
      if (match) {
        this.user.phone = match[1] + '.' + match[2] + '.' + match[3]
      }
    },
    showToast(title, icon, text, variant, position) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      },
      {
        position,
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
